<template>
    <v-container>
        
        <v-card elevation="2">

            <v-card-title class="pb-0 primary--text">Set Min Max Parameter</v-card-title>

            <v-form>
                <v-container>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        >
                            <v-subheader class="pa-1 primary--text">
                                <v-icon style="color:#0199be;" class="mr-2">mdi-store-24-hour</v-icon>
                                Station
                            </v-subheader>
                            <v-select
                            v-on:change="selectValueStation"
                            :items="itemStation"
                            label="Station"
                            required
                            outlined 
                            dense
                            >
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-tabs
                    v-model="tabs"
                    >
                        <v-tab v-for="item in tabsMinMax" :key="item.tab">
                            {{ item.tabName }}
                        </v-tab>
                    </v-tabs>

                    <!-- Container Tabs -->
                    <v-tabs-items v-model="tabs">

                        <!-- Tab Flag & Outlier-->
                        <v-tab-item class="container-tab-modul">

                            <v-card
                            class="mt-4"
                            tile
                            elevation="0" 
                            :loading="loadingForm">
                                <v-form>

                                    <v-col cols="12" md="12">

                                            <v-row>
                                                <v-col class="mr-5">
                                                    <v-card-title v-if="this.paramArray.length > 0" class="primary--text pl-2 pt-0">
                                                        Parameter
                                                    </v-card-title>
                                                </v-col>
                                                <v-col>
                                                    <v-card-title v-if="this.paramArray.length > 0" class="primary--text pl-0 pt-0">
                                                        DOR
                                                    </v-card-title>
                                                </v-col>
                                                <v-col class="pr-0">
                                                    
                                                </v-col>
                                                <v-col>
                                                    <v-card-title v-if="this.paramArray.length > 0" class="primary--text pl-0 pt-0">
                                                        Outlier
                                                    </v-card-title>
                                                </v-col>
                                                <v-col>
                                                    
                                                </v-col>
                                            </v-row>
                                        
                                            <v-row
                                            class="my-2 pa-2 curHover"
                                            no-gutters
                                            v-for="item in paramArray"
                                            :key="item.paramArray"
                                            >
                                                <v-col class="primary--text mr-5">
                                                    <div>
                                                    {{item.text}}
                                                    </div>
                                                </v-col>

                                                <v-col class="primary--text">
                                                    <div>
                                                    <v-text-field
                                                        v-model="item.flagMin"
                                                        class="pr-5"
                                                        type="number"
                                                        dense
                                                        outlined
                                                        label="Minimum DOR"
                                                        
                                                    ></v-text-field>
                                                    <!-- @input="checkInput" -->
                                                    </div>
                                                </v-col>

                                                <v-col class="primary--text">
                                                    <div>
                                                    <v-text-field
                                                        v-model="item.flagMax"
                                                        class="pr-5"
                                                        type="number"
                                                        dense
                                                        outlined
                                                        label="Maximum DOR"
                                                    ></v-text-field>
                                                    </div>
                                                </v-col>

                                                <v-col class="primary--text">
                                                    <div>
                                                    <v-text-field
                                                        v-model="item.outlierMin"
                                                        class="pr-5"
                                                        type="number"
                                                        dense
                                                        outlined
                                                        label="Minimum Outlier"
                                                    ></v-text-field>
                                                    </div>
                                                </v-col>

                                                <v-col class="primary--text">
                                                    <div>
                                                    <v-text-field
                                                        v-model="item.outlierMax"
                                                        class="pr-5"
                                                        type="number"
                                                        dense
                                                        outlined
                                                        label="Maximum Outlier"
                                                    ></v-text-field>
                                                    </div>
                                                </v-col>



                                            </v-row>
                                        </v-col>

                                        <v-col>
                                            <v-btn
                                            class="mr-4 primary"
                                            v-if="this.paramArray.length > 0"
                                            @click="submitQaqc"
                                            >
                                            Submit
                                            </v-btn>
                                        </v-col>

                                </v-form>
                            </v-card>

                        </v-tab-item>

                        <!-- Tab Exceedance -->
                        <v-tab-item class="container-tab-modul">
                            
                            <v-card
                            class="mt-4"
                            tile
                            elevation="0" 
                            :loading="loadingForm">
                                <v-form>

                                    <v-col cols="12" md="12">

                                            <v-row>
                                                <v-col class="mr-5">
                                                    <v-card-title v-if="this.paramArray.length > 0" class="primary--text pl-2 pt-0">
                                                        Parameter
                                                    </v-card-title>
                                                </v-col>
                                                <v-col>
                                                    <v-card-title v-if="this.paramArray.length > 0" class="primary--text pl-0 pt-0">
                                                        Exceedance
                                                    </v-card-title>
                                                </v-col>
                                                <v-col>
                                                    
                                                </v-col>
                                            </v-row>
                                        
                                            <v-row
                                            class="my-2 pa-2 curHover"
                                            no-gutters
                                            v-for="item in paramArray2"
                                            :key="item.paramArray"
                                            >
                                                <v-col class="primary--text mr-5">
                                                    <div>
                                                    {{item.text}}
                                                    </div>
                                                </v-col>

                                                <v-col class="primary--text">
                                                    <div>
                                                    <v-text-field
                                                        v-if="item.value == 'WQI'"
                                                        v-model="item.class"
                                                        class="pr-5"
                                                        dense
                                                        outlined
                                                        label="Class"
                                                    ></v-text-field>
                                                    <v-text-field
                                                        v-if="item.value != 'WQI'"
                                                        v-model="item.minExceed"
                                                        class="pr-5"
                                                        type="number"
                                                        dense
                                                        outlined
                                                        label="Minimum Exceedance"
                                                    ></v-text-field>
                                                    </div>
                                                </v-col>

                                                <v-col class="primary--text">
                                                    <div>
                                                    <v-text-field
                                                        v-if="item.value != 'WQI'"
                                                        v-model="item.maxExceed"
                                                        class="pr-5"
                                                        type="number"
                                                        dense
                                                        outlined
                                                        label="Maximum Exceedance"
                                                    ></v-text-field>
                                                    </div>
                                                </v-col>



                                            </v-row>
                                        </v-col>

                                        <v-col>
                                            <v-btn
                                            class="mr-4 primary"
                                            v-if="this.paramArray.length > 0"
                                            @click="submitExceed"
                                            >
                                            Submit
                                            </v-btn>
                                        </v-col>

                                </v-form>
                            </v-card>
                            
                        </v-tab-item>
                        

                    </v-tabs-items>

                </v-container>
            </v-form>

            <!-- <v-card outlined class="ma-5 pa-5"> -->
            
            
            <!-- </v-card> -->

            

        </v-card>

        <!-- Popups/Dialogs Success Edit Min Max QAQC/Exceed Added-->
        <div class="text-center">
            <v-dialog
            v-model="dialogSuccessEditMinMaxFlag"
            width="500"
            >
            <v-card>
                <v-card-title class="text-h5 success white--text">
                Success! <v-icon style="color:white;margin-left:15px;">mdi-check-circle</v-icon>
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                {{TabTypeText}} Min Max successfully submitted.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    style="color:#106f79 !important;"
                    color="success"
                    text
                    @click="dialogSuccessEditMinMaxFlag = false;reloadPage();"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>

        <!-- Popups/Dialogs Error Edit Min Max QAQC/Exceed-->
        <div class="text-center">
            <v-dialog
            v-model="dialogErrorEditMinMaxFlag"
            width="500"
            >
            <v-card>
                <v-card-title class="text-h5 error white--text">
                Error! <v-icon style="color:white;margin-left:15px;">mdi-alert-circle</v-icon>
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                Something when wrong. Please try again.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    style="color:red !important;"
                    color="error"
                    text
                    @click="dialogErrorEditMinMaxFlag = false"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>

    </v-container>
</template>

<script>
import axios from "axios";

export default {
    data: () => ({

        TabTypeText: "",
        dialogSuccessEditMinMaxFlag: false,
        dialogErrorEditMinMaxFlag: false,

        itemStation: [],
        valStation : null,
        listAllParams: [],
        listAllQAQC: [],
        OneStationQAQC: [],
        listAllExceedance: [],
        OneStationExceedance: [],

        tabs: null,
        tabsMinMax: [
            {tabName: "DOR & Outlier"},
            {tabName: "Exceedance"},
        ],

        paramItems: [],
        paramItems2: [],
        paramArray: [],
        paramArray2: [],

        manualListParam: {
            WQI_CLASS: "",
            DO_CON_ACTIVE: "",
            "4_4_DDT_ACTIVE": "",
            AG_ACTIVE: "",
            AL_ACTIVE: "",
            ALDRIN_DIELDRIN_ACTIVE: "",
            AS_ACTIVE: "",
            B_ACTIVE: "",
            BA_ACTIVE: "",
            BHC_ACTIVE: "",
            BOD_ACTIVE: "",
            BR_ACTIVE: "",
            CA_ACTIVE: "",
            CCE_ACTIVE: "",
            CD_ACTIVE: "",
            CDOM_ACTIVE: "",
            CHLORDANE_ACTIVE: "",
            CHLOROPHYLL_A_ACTIVE: "",
            CL_ACTIVE: "",
            CL2_ACTIVE: "",
            CN_ACTIVE: "",
            CO2_ACTIVE: "",
            COD_ACTIVE: "",
            COLOUR_ACTIVE: "",
            CONDUCTIVITY_ACTIVE: "",
            CR_ACTIVE: "",
            CR_III_ACTIVE: "",
            CR_IV_ACTIVE: "",
            CU_ACTIVE: "",
            DEPTH_ACTIVE: "",
            DO_SAT_ACTIVE: "",
            DOC_ACTIVE: "",
            E_COLI_ACTIVE: "",
            EC_ACTIVE: "",
            ENDOSULFAN_ACTIVE: "",
            F_ACTIVE: "",
            FC_ACTIVE: "",
            FDOM_ACTIVE: "",
            FE_ACTIVE: "",
            FLOWRATE_ACTIVE: "",
            GROSS_ALPHA_ACTIVE: "",
            GROSS_BETA_ACTIVE: "",
            HARDNESS_ACTIVE: "",
            HEDONAL_2_4_D_ACTIVE: "",
            HEPTACHLOR_EPOXIDE_ACTIVE: "",
            HG_ACTIVE: "",
            K_ACTIVE: "",
            LINDANE_ACTIVE: "",
            MBAS_ACTIVE: "",
            MG_ACTIVE: "",
            MN_ACTIVE: "",
            NA_ACTIVE: "",
            NH3N_ACTIVE: "",
            NH4_ACTIVE: "",
            NI_ACTIVE: "",
            NO2_ACTIVE: "",
            NO3_ACTIVE: "",
            OG_EDIBLE_ACTIVE: "",
            OG_MINERAL_ACTIVE: "",
            P_ACTIVE: "",
            PARAQUAT_ACTIVE: "",
            PB_ACTIVE: "",
            PCB_ACTIVE: "",
            pH_ACTIVE: "",
            PHENOL_ACTIVE: "",
            PO4_3_ACTIVE: "",
            RA226_ACTIVE: "",
            S_ACTIVE: "",
            SALINITY_ACTIVE: "",
            SE_ACTIVE: "",
            SN_ACTIVE: "",
            SO4_ACTIVE: "",
            SOLAR_BATTERY_ACTIVE: "",
            SILVEX_2_4_5_TP_ACTIV: "",
            SR_90_ACTIVE: "",
            TC_ACTIVE: "",
            TDS_ACTIVE: "",
            TEMPERATURE_ACTIVE: "",
            TOC_ACTIVE: "",
            TRIOXONE_2_4_5_T_ACTIVE: "",
            TSS_ACTIVE: "",
            TURBIDITY_ACTIVE: "",
            U_ACTIVE: "",
            VELOCITY_ACTIVE: "",
            ZN_ACTIVE: "",
            TRYPHPTOPHAN_ACTIVE: ""
        },

        jsonEditQAQC: {},
        jsonEditExceed: {},

        loadingForm: false,

    }),
    methods: {

        // LOAD DATA PROCESS ====================================================================================

        loadAllStation(){

            this.stationDataPost = [];
            this.itemStation = [];

            axios.get('https://apis.spatialworks.com.my/sarawak/cms/stations', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                for (let i = 0; i < response.data.length; i++) {
                    this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);
                }
                
            })
            .catch(error => {
                console.log(error);
            })
        },

        loadAllListParameter(){
            axios.get('https://apis.spatialworks.com.my/sarawak/cms/params', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                // for (let i = 0; i < response.data.length; i++) {
                //     if (response.data[i].value != "WQI" && response.data[i].value != "SI_DO_SAT"
                //     && response.data[i].value != "DO_CON" && response.data[i].value != "DO_SAT"
                //     && response.data[i].value != "SI_BOD" && response.data[i].value != "BOD"
                //     && response.data[i].value != "SI_COD" && response.data[i].value != "COD"
                //     && response.data[i].value != "SI_NH3N" && response.data[i].value != "NH3N"
                //     && response.data[i].value != "SI_TSS" && response.data[i].value != "TSS"
                //     && response.data[i].value != "SI_pH" && response.data[i].value != "pH") {
                //         this.listAllParams.push(response.data[i])
                //     }
                // }

                // for (let i = 0; i < response.data.length; i++) {
                //     if (response.data[i].value != "WQI") {
                //         this.listAllParams.push(response.data[i])
                //     }
                // }

                this.listAllParams = response.data;
                
                
            })
            .catch(error => {
                console.log(error);
            })
        },

        loadAllQAQC(){
            axios.get('https://apis.spatialworks.com.my/sarawak/cms/qaqc1', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                this.listAllQAQC = [];
                this.listAllQAQC = response.data;
                
            })
            .catch(error => {
                console.log(error);
            })
        },

        loadAllExceedance(){
            axios.get('https://apis.spatialworks.com.my/sarawak/maint/exceedanceall', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                this.listAllExceedance = [];
                this.listAllExceedance = response.data;
                
            })
            .catch(error => {
                console.log(error);
            })
        },


        // DISPLAY DATA ON FORM PROCESS ====================================================================================

        selectValueStation: function(e) {

            this.loadingForm = true;

            this.valStation = e.split(" - ")[0];
            this.OneStationQAQC = [];
            this.OneStationExceedance = [];
            this.paramItems = [];
            this.paramItems2 = [];
            this.paramArray = [];
            this.paramArray2 = [];

            axios.get('https://apis.spatialworks.com.my/sarawak/cms/stations', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                let dataOneStation = [];
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].stationId == this.valStation) {
                        dataOneStation.push(response.data[i]);
                    }
                }

                console.log(dataOneStation);

                this.loadFormMinMax(this.valStation,dataOneStation[0]);
                
            })
            .catch(error => {
                console.log(error);
            })

            

        },

        loadFormMinMax(station,dataParam){

            console.log(station);
            // console.log(dataParam);
            console.log(this.listAllParams);

            // Get Data QAQC Based On Station Selected
            for (let i = 0; i < this.listAllQAQC.length; i++) {
                if (this.listAllQAQC[i].STATION_ID == station) {
                    this.OneStationQAQC.push(this.listAllQAQC[i]);
                }
            }

            // Get Data Exceedance Based On Station Selected
            for (let i = 0; i < this.listAllExceedance.length; i++) {
                if (this.listAllExceedance[i].STATION_ID == station) {
                    this.OneStationExceedance.push(this.listAllExceedance[i]);
                }
            }

            console.log(this.OneStationExceedance);

            let keyDataParam = Object.keys(dataParam)
            // Listkan Data Parameter Based On Station Selected
            for (let i = 0; i < this.listAllParams.length; i++) {
                for (let x = 0; x < keyDataParam.length; x++) {
                    if (this.listAllParams[i].reading == dataParam[keyDataParam[x]] || this.listAllParams[i].reading == "_"+dataParam[keyDataParam[x]]) {
                        this.paramItems.push(this.listAllParams[i]);
                    }
                }
            }
            // console.log(this.paramItems);

            for (let i = 0; i < this.listAllParams.length; i++) {
                for (let x = 0; x < keyDataParam.length; x++) {
                    if (this.listAllParams[i].reading == dataParam[keyDataParam[x]] || this.listAllParams[i].reading == "_"+dataParam[keyDataParam[x]]) {
                        this.paramItems2.push(this.listAllParams[i]);
                    }
                }
                if (this.listAllParams[i].reading == "WQI") {
                    this.paramItems2.unshift(this.listAllParams[i]);
                }
            }

            this.createObjQaqc();

        },

        createObjQaqc(){

            for (let key in this.paramItems) {
                this.paramArray.push({ 
                    text: this.paramItems[key].text,
                    value: this.paramItems[key].value,
                    flagMin: null,
                    flagMax: null,
                    outlierMin: null,
                    outlierMax: null 
                });
            }
            // console.log(this.paramArray);

            for (let key in this.paramItems2) {
                if (this.paramItems2[key].value != "WQI") {
                    this.paramArray2.push({ 
                        text: this.paramItems2[key].text,
                        value: this.paramItems2[key].value,
                        minExceed: null,
                        maxExceed: null,
                    });
                }
                else{
                    this.paramArray2.unshift({
                        text: this.paramItems2[key].text,
                        value: this.paramItems2[key].value,
                        class: null,
                    })
                }
                
            }


            this.displayDataQAQC(this.OneStationQAQC[0],this.OneStationExceedance[0]);

            
        },

        displayDataQAQC(dataQaqc,dataQaqc2){

            let keyData = Object.keys(dataQaqc)
            // console.log(this.paramArray);
            // console.log(dataQaqc);
            for (let i = 0; i < this.paramArray.length; i++) {
                for (let x = 0; x < keyData.length; x++) {
                    if (this.paramArray[i].value == keyData[x].split("_MIN")[0] || this.paramArray[i].value == "_"+keyData[x].split("_MIN")[0]) {
                        // this.paramArray[i].flagMin = parseFloat(dataQaqc[keyData[x]])
                        this.paramArray[i].flagMin = dataQaqc[keyData[x]];
                    }
                    else if (this.paramArray[i].value == keyData[x].split("_MAX")[0] || this.paramArray[i].value == "_"+keyData[x].split("_MAX")[0]) {
                        // this.paramArray[i].flagMax = parseFloat(dataQaqc[keyData[x]])
                        this.paramArray[i].flagMax = dataQaqc[keyData[x]];
                    }
                    else if (this.paramArray[i].value == keyData[x].split("_OUTLIER_MIN")[0] || this.paramArray[i].value == "_"+keyData[x].split("_OUTLIER_MIN")[0]) {
                        // this.paramArray[i].outlierMin = parseFloat(dataQaqc[keyData[x]])
                        this.paramArray[i].outlierMin = dataQaqc[keyData[x]];
                    }
                    else if (this.paramArray[i].value == keyData[x].split("_OUTLIER_MAX")[0] || this.paramArray[i].value == "_"+keyData[x].split("_OUTLIER_MAX")[0]) {
                        this.paramArray[i].outlierMax = dataQaqc[keyData[x]];
                    }
                }
            }

            console.log(dataQaqc2);

            let keyData2 = Object.keys(dataQaqc2)
            // console.log(this.paramArray2);
            console.log(dataQaqc2);
            for (let i = 0; i < this.paramArray2.length; i++) {
                for (let x = 0; x < keyData2.length; x++) {
                    if (this.paramArray2[i].value == keyData2[x].split("_MIN")[0] || this.paramArray2[i].value == "_"+keyData2[x].split("_MIN")[0]) {
                        this.paramArray2[i].minExceed = dataQaqc2[keyData2[x]]
                    }
                    else if (this.paramArray2[i].value == keyData2[x].split("_MAX")[0] || this.paramArray2[i].value == "_"+keyData2[x].split("_MAX")[0]) {
                        this.paramArray2[i].maxExceed = dataQaqc2[keyData2[x]]
                    }
                    else if (this.paramArray2[i].value == keyData2[x].split("_CLASS")[0]) {
                        this.paramArray2[i].class = dataQaqc2[keyData2[x]]
                    }
                }
            }

            this.loadingForm = false;
            
        },


        // SUBMIT DATA PROCESS ====================================================================================

        // checkInput(e){
        //     console.log(e);
        //     console.log(this.paramArray);
        // },

        submitQaqc(){
            
            console.log("submit qaqc?");

            console.log(this.paramArray);
            
            let keyData = Object.keys(this.manualListParam);
            for(let i in keyData){

                if (keyData[i] != "WQI_CLASS") {
                    this.jsonEditQAQC["STATION_ID"] = null;
                    this.jsonEditQAQC[keyData[i].split("_ACTIVE")[0]+"_MIN"] = null;
                    this.jsonEditQAQC[keyData[i].split("_ACTIVE")[0]+"_MAX"] = null;
                    this.jsonEditQAQC[keyData[i].split("_ACTIVE")[0]+"_OUTLIER_MIN"] = null;
                    this.jsonEditQAQC[keyData[i].split("_ACTIVE")[0]+"_OUTLIER_MAX"] = null;
                }
                
            }

            let keyData2 = Object.keys(this.jsonEditQAQC);
            for (let i in keyData2) {
                this.jsonEditQAQC[keyData2[i]] = this.OneStationQAQC[0][keyData2[i]];
            }

            for(let i in this.paramArray){
                for(let x in keyData2){
                    if (this.paramArray[i].value+"_MIN" == keyData2[x] || this.paramArray[i].value+"_MIN" == "_"+keyData2[x]) {
                        this.jsonEditQAQC[keyData2[x]] = this.paramArray[i].flagMin;
                    }
                    else if (this.paramArray[i].value+"_MAX" == keyData2[x] || this.paramArray[i].value+"_MAX" == "_"+keyData2[x]) {
                        this.jsonEditQAQC[keyData2[x]] = this.paramArray[i].flagMax;
                    }
                    else if (this.paramArray[i].value+"_OUTLIER_MIN" == keyData2[x] || this.paramArray[i].value+"_OUTLIER_MIN" == "_"+keyData2[x]) {
                        this.jsonEditQAQC[keyData2[x]] = this.paramArray[i].outlierMin;
                    }
                    else if (this.paramArray[i].value+"_OUTLIER_MAX" == keyData2[x] || this.paramArray[i].value+"_OUTLIER_MAX" == "_"+keyData2[x]) {
                        this.jsonEditQAQC[keyData2[x]] = this.paramArray[i].outlierMax;
                    }
                }
            }

            this.TabTypeText = "Flag & Outlier"

            console.log(this.jsonEditQAQC);
            let jsonStringEditQAQC = JSON.stringify(this.jsonEditQAQC);

            console.log(jsonStringEditQAQC);

            axios.put('https://apis.spatialworks.com.my/sarawak/cms/qaqc1', jsonStringEditQAQC, {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.user.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                console.log(response);
                this.dialogSuccessEditMinMaxFlag = true;
            })
            .catch(error => {
                console.log(error);
                this.dialogSuccessEditMinMaxFlag = false;
                this.dialogErrorEditMinMaxFlag = true;
                this.TabTypeText = "";
            })

        },


        submitExceed(){

            let keyData = Object.keys(this.manualListParam);
            for(let i in keyData){
                if (keyData[i] != "WQI_CLASS"){
                    this.jsonEditExceed["STATION_ID"] = null;
                    this.jsonEditExceed[keyData[i].split("_ACTIVE")[0]+"_MIN"] = null;
                    this.jsonEditExceed[keyData[i].split("_ACTIVE")[0]+"_MAX"] = null;
                }
                else{
                    this.jsonEditExceed[keyData[i]] = null;
                }
                
            }


            let keyData2 = Object.keys(this.jsonEditExceed);
            for (let i in keyData2) {
                this.jsonEditExceed[keyData2[i]] = this.OneStationQAQC[0][keyData2[i]];
            }

            for(let i in this.paramArray2){
                for(let x in keyData2){
                    if (this.paramArray2[i].value+"_MIN" == keyData2[x] || this.paramArray2[i].value+"_MIN" == "_"+keyData2[x]) {
                        this.jsonEditExceed[keyData2[x]] = this.paramArray2[i].minExceed;
                    }
                    else if (this.paramArray2[i].value+"_MAX" == keyData2[x] || this.paramArray2[i].value+"_MAX" == "_"+keyData2[x]) {
                        this.jsonEditExceed[keyData2[x]] = this.paramArray2[i].maxExceed;
                    }
                    else if (this.paramArray2[i].value+"_CLASS" == keyData2[x]) {
                        this.jsonEditExceed[keyData2[x]] = this.paramArray2[i].class;
                    }
                }
            }

            this.TabTypeText = "Exceedance"

            // console.log(this.jsonEditExceed);
            let jsonStringEditExceed = JSON.stringify(this.jsonEditExceed);

            // console.log(jsonStringEditExceed);

            axios.put('https://apis.spatialworks.com.my/sarawak/maint/exceedanceedit', jsonStringEditExceed, {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.user.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                console.log(response);
                this.dialogSuccessEditMinMaxFlag = true;
            })
            .catch(error => {
                console.log(error);
                this.dialogSuccessEditMinMaxFlag = false;
                this.dialogErrorEditMinMaxFlag = true;
                this.TabTypeText = "";
            })

        }

    },
    computed: {},
    // created(){
    //     for (let key in this.paramItems) {
    //         this.paramArray.push({ name: key, param: key.split("_ACTIVE")[0], value: null, min: null, max: null, outlierMin: null, outlierMax: null });
    //     }
    // },
    mounted(){
        this.loadAllStation();
        this.loadAllListParameter();
        this.loadAllQAQC();
        this.loadAllExceedance();
    }
}
</script>

<style scoped>
    
    .curHover:hover{
        background: #e1f3f7;
    }

</style>